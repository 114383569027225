/**
 * Bunkai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: test@bunkai.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ScanPlanResult { 
    readonly createdAt?: string;
    readonly id?: string;
    passed?: boolean | null;
    reasonCode?: ScanPlanResult.ReasonCodeEnum | null;
    risk?: ScanPlanResult.RiskEnum | null;
    status: ScanPlanResult.StatusEnum;
    statusDisplay: string;
    readonly updatedAt?: string;
}
export namespace ScanPlanResult {
    export type ReasonCodeEnum = 'TIME_LIMIT_EXCEEDED' | 'MANUALLY_INTERRUPTED' | 'EXECUTION_ERROR' | 'ANALYZING_ERROR';
    export const ReasonCodeEnum = {
        TimeLimitExceeded: 'TIME_LIMIT_EXCEEDED' as ReasonCodeEnum,
        ManuallyInterrupted: 'MANUALLY_INTERRUPTED' as ReasonCodeEnum,
        ExecutionError: 'EXECUTION_ERROR' as ReasonCodeEnum,
        AnalyzingError: 'ANALYZING_ERROR' as ReasonCodeEnum
    };
    export type RiskEnum = 'Critical' | 'High' | 'Medium' | 'Low' | 'Info';
    export const RiskEnum = {
        Critical: 'Critical' as RiskEnum,
        High: 'High' as RiskEnum,
        Medium: 'Medium' as RiskEnum,
        Low: 'Low' as RiskEnum,
        Info: 'Info' as RiskEnum
    };
    export type StatusEnum = 'TBD' | 'IN_PROGRESS' | 'ANALYZING' | 'FINISHED' | 'STOPPED';
    export const StatusEnum = {
        Tbd: 'TBD' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Analyzing: 'ANALYZING' as StatusEnum,
        Finished: 'FINISHED' as StatusEnum,
        Stopped: 'STOPPED' as StatusEnum
    };
}


